<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="1" :dialogVisible2="topDialogVisible"></top>
      <div class="container-box">
        <div class="width-100 flex-row justify-content-spaceBetween align-items-star">
          <div class="left">
            <el-image v-if="imageList.length > 0" class="image" :src="imageList[0]" fit="contain"></el-image>
          </div>
          <div class="right">
            <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
              <div class="width-85">
                <p class="fs-super-big black fw-mid margin-b title">{{ data.title }}</p>
                <p class="fs-mid-big orange fw-mid margin-b">{{ data.worksPrice === '0' ? '价格面议' : '￥' + data.worksPrice }}</p>
              </div>
              <div class="width-15 textAlign-c">
                <img :src="require(data.like ? '../assets/image/s2.png' : '../assets/image/s1.png')" @click="clickLike" width="40%" alt="" />
                <p class="darkgrey fs-mid margin-ts opacity-5">{{ data.collectNum }}</p>
              </div>
            </div>
            <p class="darkgrey fs-big margin-t opacity-8">产品对应版权证书编号：{{ data.copyrightNo }}</p>
            <p class="darkgrey fs-big margin-t opacity-8">联系人：{{ data.worksContactName }}</p>
            <p class="darkgrey fs-big margin-t opacity-8">联系电话：{{ data.worksContactPhone }}</p>
            <!-- <p class="darkgrey fs-big margin-t opacity-8">授权方式：{{ authorizationTypeDict.get(parseInt(data.authorizationType)) }}</p> -->
            <!-- <van-stepper class="stepper" v-model="value" button-size="50" input-width="80" /> -->
            <el-button class="button" type="primary" round @click="buyClick" style="margin-top: 50px">我有兴趣</el-button>
            <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
              <div class="width-100 top-title padding-90 flex-row justify-content-start align-items-center">
                <div class="width-10"><img src="../assets/image/03.png" width="60%" alt="" /></div>
                <p class="fs-mid-big theme-blue">卖家联系方式</p>
              </div>
              <div class="width-100 bottom-box padding-90">
                <p class="fs-big fw-mid black position-relative" style="left: -8px">{{ data.title }}</p>
                <p class="darkgrey fs-mid margin-ts opacity-8">作者/来源：{{ data.copyrightOwnerName }}</p>
                <p class="darkgrey fs-mid margin-ts opacity-8">证书编号：{{ data.copyrightNo }}</p>
                <p class="darkgrey fs-mid margin-ts opacity-8">版权类型：{{ data.copyrightTypeName }}</p>
                <p class="darkgrey fs-mid margin-ts opacity-8">联系人：{{ data.copyrightContactName }}</p>
                <p class="darkgrey fs-mid margin-ts opacity-8">联系方式：{{ data.copyrightContactPhone }}</p>
              </div>
            </el-dialog>
          </div>
        </div>
        <div class="desc-box br-mid bg-white">
          <p class="fs-mid-big title black fw-mid border-b">作品介绍</p>
          <p class="fs-mid darkgrey opacity-5 width-100 line-height margin-b">{{ data.instruction }}</p>
          <div v-for="(item, index) in imageList" :key="index" class="width-100 margin-b">
            <el-image :src="item" class="width-100" style="height: 100%" fit="cover"></el-image>
          </div>

          <!--          <p class="fs-mid darkgrey margin-bs">作品简介及创意</p>-->
          <!--          <p class="fs-mid darkgrey opacity-5 width-100 line-height">{{ info.introduce }}</p>-->
          <!--          <p class="fs-mid darkgrey margin-bs margin-t">创作经过及意图</p>-->
          <!--          <p class="fs-mid darkgrey opacity-5 width-100 line-height">{{ info.creativeProcess }}</p>-->
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
// import { registrationInfo, registrationDetailInfo } from '@/api/tranCopyrightRegistration';
import { registrationInfo, wantBuy } from '@/api/tranCopyrightWorks';
import { workLike } from '@/api/tranCopyrightCollect';
import { getDict } from '@/api/dict';

export default {
  name: 'copyrightDisplayDetail',
  components: {
    top,
    bottom
  },
  data() {
    return {
      id: this.$route.query.id,
      topDialogVisible: false,
      dialogVisible: false,
      data: {},
      info: {
        ifCollect: false,
        picture: [
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        ],
        title: '《曹村进士小曹》卡通IP',
        author: '温州易品智和文化创意有限公司',
        price: '价格面议',
        certificateNumber: '68554129847142',
        type: '美术',
        mode: '按数量购买',
        contacts: '陈某某',
        mobile: '15266666666',
        introduce: '雕塑《文林、何文渊》，是为打造五马清廉街区，推进廉洁文化教育基地建设，在华盖山麓建立两人雕像，以示纪念而创 作的作品。', //作品简介及创意
        creativeProcess:
          '温州古城自东晋太宁元年建郡以来，曾涌现出许多为官清廉、大公无私的郡守，在千年白鹿城留下众多佳话。其中何文渊、 文林就曾先后出任' //创作经过及意图
      },
      imageList: [],
      copyrightTypeDict: new Map(),
      authorizationTypeDict: new Map(),
      copyrightUsageDict: new Map(),
      value: 1,
      params: {
        params: {}
      }
    };
  },
  async mounted() {
    this.copyrightTypeDict = new Map(
      (await getDict('bqsb_copyright_type')).map((obj) => {
        return [obj.value, obj.text];
      })
    );
    this.authorizationTypeDict = new Map(
      (await getDict('tran_authorization_type')).map((obj) => {
        return [obj.value, obj.text];
      })
    );
    this.copyrightUsageDict = new Map(
      (await getDict('tran_copyright_usage')).map((obj) => {
        return [obj.value, obj.text];
      })
    );

    // registrationInfo(this.$route.query.id).then((res) => {
    //   console.log(res);
    //   if (res.code === 200) {
    //     this.data = res.data;
    //     if (res.data.copyrightImage !== null)
    //       this.imageList = res.data.copyrightImage.split(',').map((image) => {
    //         return config.IMAGE_PREFIX + image;
    //       });
    //   }
    // });
    this.checkList();
    this.getRegistrationInfo();
  },
  methods: {
    async getRegistrationInfo() {
      const info = await registrationInfo(this.id, this.params);
      if (info.code === 200) {
        this.data = info.data;
        if (info.data.worksImage !== null)
          this.imageList = info.data.worksImage.split(',').map((image) => `${process.env.VUE_APP_IMAGE_PREFIX}${image}`);
        if (info.data.copyrightCardImage !== null)
          this.imageList = this.imageList.concat(
            info.data.copyrightCardImage.split(',').map((image) => `${process.env.VUE_APP_IMAGE_PREFIX}${image}`)
          );
      }
    },
    // async getRegistrationDetailInfo() {
    //   const info = await registrationDetailInfo(this.id);
    //   if (info.code === 200) {
    //     this.data = info.data;
    //     if (info.data.copyrightImage !== null)
    //       this.imageList = info.data.copyrightImage.split(',').map((image) => `${process.env.VUE_APP_IMAGE_PREFIX}${image}`);
    //   }
    // },
    async buyClick() {
      if (localStorage.getItem('bqsb_login')) {
        await wantBuy(this.data.id);
      } else {
        this.topDialogVisible = false;
        this.topDialogVisible = true;
      }
      // if (localStorage.getItem('bqsb_login')) {
      //   // 登录存在
      //   this.checkList();
      //   await this.getRegistrationDetailInfo();
      //   this.dialogVisible = true;
      // } else {
      //   this.topDialogVisible = false;
      //   this.topDialogVisible = true;
      // }
    },
    async checkList() {
      if (localStorage.getItem('bqsb_login')) {
        this.params.params.like = true;
      }
    },
    async clickLike() {
      if (localStorage.getItem('bqsb_login')) {
        // 登录存在
        await workLike(this.data.id);
        this.data.like = !this.data.like;
        if (this.data.like) {
          this.data.collectNum = this.data.collectNum + 1;
        } else {
          this.data.collectNum = this.data.collectNum - 1;
        }
      } else {
        this.topDialogVisible = false;
        this.topDialogVisible = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    padding: 40px 0;
    .left {
      width: 60%;
      .image {
        height: 500px;
        width: 95%;
      }
    }
    .right {
      width: 40%;
      .title {
        position: relative;
        left: -12px;
      }
      .stepper {
        margin: 25px 0;
      }
      .button {
        padding: 20px 50px;
        border-radius: 50px;
        background: linear-gradient(to right, #56a7ff, #3f96ff);
        span {
          font-size: 32px !important;
        }
      }
    }
    .desc-box {
      margin-top: 40px;
      padding: 30px 3%;
      .title {
        padding-bottom: 20px;
        margin-bottom: 30px;
      }
    }
  }
  .top-title {
    background: #f3f9ff;
  }
  .bottom-box {
    background: url('../assets/image/logo1.png') right bottom no-repeat;
    background-size: 55%;
    padding-bottom: 40px;
  }
}
</style>
<style>
.van-stepper__input {
  font-size: 24px;
  background: white;
  border: 1px solid #eeeeee;
  margin: 0 0px;
}
.van-stepper__plus,
.van-stepper__minus {
  background: #f7f7f7;
  border: 1px solid #eeeeee;
  color: #999999;
}
.van-stepper__plus::before,
.van-stepper__minus::before {
  width: 30%;
}
.van-stepper__plus::after,
.van-stepper__minus::after {
  height: 30%;
}
.button span {
  font-size: 24px !important;
}
</style>
